<template>
  <div class="image-gallery">
    <div
      class="section-one position-relative"
      :style="{ backgroundImage: `url(${current})` }">
      <!-- <span
        class="position-absolute z-index-50 f-s-50 f-c-0 f-f-raleway font-bold"
        style="background-color: rgba(1, 1, 1, 0.3); padding: 1rem"
      >
        {{ name }}
      </span> -->
    </div>

    <div class="flex-row section-gallery bg-c-0 justify-content-center">
      <ul class="flex-row">
        <li
          v-for="(item, i) in images"
          :key="i + 'abc'"
          :style="{ backgroundImage: `url(${item.imageSrc})` }"
          :class="{ 'current-cover': item.imageSrc === current }"
          class="cursor-pointer"
          @click="onSwitchCover(item)"
        ></li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    name: {
      type: String,
      default: "",
    },
    images: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      current: this.images[0].imageSrc,
    }
  },
  watch: {
    images(newVal) {
      this.current = newVal[0].imageSrc;
    }
  },
  methods: {
    onSwitchCover(item) {
      this.current = item.imageSrc;
    }
  }
};
</script>
<style lang="scss" scoped>
.image-gallery {
  .section-one {
    height: 51.3rem;
    background-size: cover;
    background-position: center center;
    background-color: #dde2e6;

    span {
      line-height: 5.9rem;
      top: 11.2rem;
      left: 14rem;
    }
  }

  .section-gallery {
    ul {
      padding: .6rem 0;

      li {
        width: 12.9rem;
        height: 7.6rem;
        background-size: cover;
        background-position: center center;
        opacity: 0.53;
        margin-right: 12px;
        backdrop-filter: blur(50px);
        background-image: url('../../../assets/images/others/img-default.png');
      }

      .current-cover {
        opacity: 1;
      }
    }
  }
}
</style>