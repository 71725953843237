export default {
  "Maritime Experiential Museum": {
    name: "Maritime Experiential Museum",
    projectType: "Theme Park",
    developer: "",
    tenure: "",
    psf: "",
    completionYear: "2010",
    totalUnits: "",
    desc: "The Maritime Experiential Museum is a complex geotechnical engineering project involving long-span reinforced concrete structures and large steel structures. New technologies were used to integrate the application and development of materials like rocks, concrete structures and steel structures.The project commenced in 2009 and was completed in 2010.",
    website:"",
    location: {lat: 1.2569975772871185, lng: 103.82026726188299},
    images: [
      {id: 0, imageSrc: require('@/assets/images/constructions/project-theme-park/museum/1.gif')},
      {id: 1, imageSrc: require('@/assets/images/constructions/project-theme-park/museum/DJI_0026.gif')}
    ]
  },
  "Universal Studios Singapore": {
    name: "Universal Studios Singapore",
    projectType: "",
    developer: "",
    tenure: "",
    psf: "",
    completionYear: "2009",
    totalUnits: "",
    desc: `Universal Studios Singapore (USS) is the world’s fourth Universal Studios and the first in Southeast Asia. This contract is the largest civil construction project awarded to a Chinese contractor in Singapore and is also the first large-scale overseas integrated theme park project undertaken by a Chinese enterprise.
    
    The USS project commenced in 2008 and was completed in 2009. Completed within 18 months, the USS project filed 18 patents and won the Green Mark GoldPlus Award (Building and Construction Authority of Singapore, BCA), the Metallurgical Industry Quality Award and the Luban Award (China’s Ministry of Housing and Urban-Rural Development, MOHURD, and the China Construction Industry Association, CCIA).
    `,
    website:"https://www.rwsentosa.com/en/attractions/universal-studios-singapore/explore",
    location: {lat: 1.254056734461937, lng: 103.82381008255646},
    images: [
      {id: 0, imageSrc: require('@/assets/images/constructions/project-theme-park/studio/1.jpg')},
      {id: 1, imageSrc: require('@/assets/images/constructions/project-theme-park/studio/2.jpg')},
      {id: 2, imageSrc: require('@/assets/images/constructions/project-theme-park/studio/3.jpg')},
      {id: 3, imageSrc: require('@/assets/images/constructions/project-theme-park/studio/20210708104745.gif')},
      {id: 4, imageSrc: require('@/assets/images/constructions/project-theme-park/studio/USS.gif')}
    ]
  },
}