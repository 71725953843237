<template>
  <section class="project-detail">
    <ImageGallery :name="projectInfo.name" :images="projectInfo.images" />

    <div class="flex-row justify-content-center">
      <div ref="overview" class="section-infor">
        <gap :height="64" />

        <Menu @on-menu="onMenu"/>

        <gap :height="60" />

        <Details :details="details" />

        <gap :height="32.4" />

        <Description :desc="description" />

        <gap :height="47" />

        <template v-if="projectInfo.codeOfProjectType === 'CONDOMINIUM'">
          <Facilities :data="facilities" />
          <gap :height="30" />
        </template>

        <template v-if="website">
          <div class="external">
            <a :href="website"> <p>{{ $t("message.pd_website") }}</p></a>
          </div>
          <gap :height="43" />
        </template>

        <p ref="location" class="f-s-17 font-bold f-c-2 f-f-arial title">
          {{ $t("message.pd_location") }}
        </p>
        <gap :height="32" />

      </div>
    </div>

    <Location :location="location"/>

    <Pagination 
      :currentProjectId="projectInfo.name" 
      :projectList="langPackage" 
      @on-pagination="onPaginationChange" />
  </section>
</template>
<script>
import jsonEn from '@/json/json-en/index.js';
import jsonZh from "@/json/json-zh/index.js";
import ImageGallery from './components/ImageGallery.vue';
import Menu from "./components/Menu.vue";
import Details from "./components/Details";
import Description from "./components/Description";
import Facilities from "./components/Facilities";
import Location from "./components/Location";
import Pagination from "./components/Pagination"

export default {
  components: {
    ImageGallery, Menu, Details, Description, Facilities, Location, Pagination
  },
  data() {
    return {
      langPackage: '',
      projectInfo: {
        name: "",
        codeOfProjectType: '',
        current: { id: 0, cover: '' },
        images: [],
      },
      description: "",
      facilities: [
        { icon: "iconbasementcarpark", label: "Basement car park" },
        { icon: "icon2971019", label: "Children’s Playground" },
        { icon: "icondropoff", label: "Drop Off Point" },
        { icon: "icon3788750", label: "Gymnasium room" },
        { icon: "icon3582873", label: "Lift lobby" },
        { icon: "icon763860", label: "Swimming pool" },
        { icon: "iconXMLID_642_", label: "Main entrance" },
        { icon: "icon783192", label: "24 hours security" },
      ],
      location: {lat: 1.2856444, lng: 103.8045647},
      operations: [],
      website: '',
    };
  },
  computed: {
    lang() {
      return this.$store.state.lang;
    },
    details: {
      get() {
        return [
          { title: this.$t("message.pd_project_name"), label: "" },
          { title: this.$t("message.pd_project_type"), label: "" },
          { title: this.$t("message.pd_developer"), label: "" },
          { title: this.$t("message.pd_tenure"), label: "" },
          { title: this.$t("message.pd_PSF"), label: "" },
          { title: this.$t("message.pd_completion_year"), label: "" },
          { title: this.$t("message.pd_total_units"), label: "" },
        ];
      },
      set(newVal) {
        return newVal;
      }
    }
  },
  watch: {
    lang(newVal) {
      this.langPackage = newVal === 'en' ? jsonEn : jsonZh;
      this.setProjectInfo(this.$route.query.id, this.langPackage);
    },
  },
  created () {
    this.langPackage = this.lang === 'en' ? jsonEn : jsonZh;
    this.setProjectInfo(this.$route.query.id, this.langPackage);
  },
  methods: {
    setProjectInfo(projectName, json) {
      const {
        name, 
        codeOfProjectType,
        projectType, 
        developer, 
        tenure, 
        psf, 
        completionYear, 
        totalUnits, 
        website, 
        location, 
        images, 
        desc,
        arrForDetail,
        } = json[projectName];
      this.projectInfo.name = name;
      this.projectInfo.images = images;
      this.description = desc;
      this.website = website;
      this.projectInfo.codeOfProjectType = codeOfProjectType;
      this.location = {lat: Number(location.lat), lng: Number(location.lng)};

      const d = [name, projectType, developer, tenure, psf, completionYear, totalUnits];
      let isCustomeDetail = arrForDetail && arrForDetail.length > 0;
      this.setProjectDetail(d, isCustomeDetail, arrForDetail);
    },
    onPaginationChange(projectName) {
      this.$router.push({path: '/project-detail', query: {id: projectName}});
      this.setProjectInfo(projectName, this.langPackage);
      window.scrollTo(0,0); // 翻页以后页面回到顶部
    },
    onMenu(item) {
      const $ = window.$;
      const el = this.$refs[item.ref];
      const h = $(el).offset().top;
      this.currentLabel = item.id;

      $("body,html").animate({ scrollTop: h - 100 }, 200);
    },

    /**
     * arr: 改版前显示的内容
     * isCustome: 是否自定义
     * list: 自定义的内容
     */
    setProjectDetail(arr, isCustome, list) {
      // 如果存在自定义的项目详情，则取arrForDetail中的数据
      if (isCustome) {
        for(let i = 0; i < list.length; i++) this.$set(this.details, i, list[i]);
      } else {
        arr.forEach((item, i) => { this.details[i].label = item; });
        this.details = JSON.parse(JSON.stringify(this.details));
      }
    }
  },
};
</script>
<style lang="scss" scoped type="text/scss">
.project-detail {
  .section-one {
    height: 71rem;
    background-size: cover;
    background-position: center center;

    span {
      line-height: 5.9rem;
      top: 11.2rem;
      left: 14rem;
    }
  }

  .section-infor {
    width: 65.9rem;
    
    .site-plan-image {
      width: 92.1rem;
    }
    .external {
      //border: solid black;
      a {
        text-decoration: none;
      }
      cursor: pointer;
      border-radius: 1rem;
      width: 100vw/1366 * 157;
      height: 100vw/1366 * 43;
      background-color: rgba(74, 145, 242, 1);

      p {
        text-align: center;
        color: white;
        line-height: 100vw/1366 * 43;
        white-space: nowrap;
        font-size: 100vw/1366 * 14;
      }
    }
  }
}
</style>