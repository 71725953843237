export default {
  "DANGA HEIGHTS": {
    name: "DANGA HEIGHTS",
    /* projectType: "城市设计和规划",
    developer: "",
    tenure: "",
    psf: "600,000m²",
    completionYear: "",
    totalUnits: "", */
    arrForDetail: [
      {title: '项目名称', label: 'DANGA HEIGHTS'},
      {title: '项目类型', label: '城镇综合发展项目'},
      {title: '开发商', label: 'MCC DANGA BAY SDN BHD'},
      {title: '国家', label: '马来西亚'},
      {title: '用地面积', label: '60 ha'},
      {title: '完成时间', label: '进行中'},
      {
        title: '服务范围', 
        label: `城市规划与城市设计<br/>
          建筑设计、土木工程、机电工程设计管理<br/>
          BIM建模`
      },
    ],
    desc: `
      占地约60公顷的Danga Heights项目由新加坡中冶置业和依斯干达海滨控股（依海控股）子公司Danga Heights Development（DHD）合资开发。项目总投资约8亿人民币，将在未来10年内分4期发展，首期发展面积约15.4公顷。

      项目位于马来西亚第二大城市——柔佛州州府新山市的依斯干达特区，靠近新山中央商务区，毗邻士古来快速公路。
      
      该项目预计将成为新山市最具发展潜力的新兴住宅区——一个拥有智能设施和尖端家居理念的智慧社区，并在未来十年促进新山市转型为更加智慧的城市。
      
      Danga Heights 项目是马来西亚依斯干达特区在疫情期间迎来的最大的外来直接投资 (FDI) 项目之一，也新加坡中冶置业首次在马来西亚展开房产合资计划。
    `,
    website:"",
    location: {lat: "", lng: ""},
    images: [
      {id: 0, imageSrc: require('@/assets/images/urban-design/danga/F_01.gif')},
      {id: 1, imageSrc: require('@/assets/images/urban-design/danga/F_02.gif')},
      {id: 2, imageSrc: require('@/assets/images/urban-design/danga/F_03.gif')},
      {id: 3, imageSrc: require('@/assets/images/urban-design/danga/F_04.gif')},
      {id: 4, imageSrc: require('@/assets/images/urban-design/danga/F_05.gif')},
    ]
  },
  "DARA SAKOR": {
    name: "七星海",
    /* projectType: "城市发展",
    developer: "",
    tenure: "",
    psf: "360,000m²",
    completionYear: "",
    totalUnits: "", */
    arrForDetail: [
      {title: '项目名称', label: '七星海'},
      {title: '项目类型', label: '新城建设'},
      {title: '开发商', label: '七星海城市发展集团有限公司'},
      {title: '国家', label: '柬埔寨'},
      {title: '用地面积', label: '360km²'},
      {title: '完成时间', label: '2019'},
      {title: '服务范围', label: '城市规划与城市设计'},
    ],
    desc: `
    七星海项目位于柬埔寨国公省西南海滨，占地360平方公里，沿泰国湾呈L型展开。项目距首都金边约260公里，距吴哥窟约560公里，距西哈努克港仅60公里。

    项目借鉴新加坡城市规划和建设经验，将可持续的城市规划、生态保护、资源综合利用、水资源管理、废弃物管理等理念，以及先进的建设和发展模式引入的项目的规划和设计之中。
    
    整个项目分为三大片区。七星海国际新区约100平方公里，打造柬埔寨全新综合旅游目的地。世贸新区约60平方公里，规划为东南亚新兴商贸金融中心。未来新区约200平方公里，将成为未来智能科技新区。塑造“生态+人文”的特色海滨城市环境；导入旅游度假、商业服务、科技生产等高端功能；关注多样宜居的生活环境设计，吸引人才留驻是项目规划的三大重要方向。
    `,
    website:"",
    location: {lat: "", lng: ""},
    images: [
      // {id: 0, imageSrc: require("@/assets/images/urban-design/dara-sakor/American_7.jpg")},
      // {id: 1, imageSrc: require("@/assets/images/urban-design/dara-sakor/c01-Overall_aerial-010.jpg")},
      {id: 2, imageSrc: require("@/assets/images/urban-design/dara-sakor/c02-3.gif")},
      {id: 3, imageSrc: require("@/assets/images/urban-design/dara-sakor/c02-aerial.gif")},
      // {id: 4, imageSrc: require("@/assets/images/urban-design/dara-sakor/c04_04.JPG")},
      {id: 5, imageSrc: require("@/assets/images/urban-design/dara-sakor/c5.gif")},
      {id: 6, imageSrc: require("@/assets/images/urban-design/dara-sakor/C08.gif")},
      // {id: 7, imageSrc: require("@/assets/images/urban-design/dara-sakor/Cambodian_9.gif")},
      // {id: 8, imageSrc: require("@/assets/images/urban-design/dara-sakor/Japanese_7.gif")},
      {id: 9, imageSrc: require("@/assets/images/urban-design/dara-sakor/Site_plan_9.gif")},
    ]
  },
}