export default {
  "The Nautical": {
    name: "The Nautical",
    codeOfProjectType: "CONDOMINIUM",
    projectType: "公寓",
    developer: "Hao Yuan Investment Pte Ltd",
    tenure: "99-year leasehold",
    psf: "27,667.8m²",
    completionYear: "2014",
    totalUnits: "435",
    desc: `Developed by Hao Yuan Investment Pte Ltd and managed by MCC Land (Singapore) Pte Ltd with China Jingye Construction Engineering (S) Pte Ltd as the main contractor, this condominium project has a total site area of 27,667 square metres and a plot ratio of 1.5. The 99-year leasehold project comprises four blocks of five-storey residential flats with a total of 435 units together with basement car parks, a swimming pool, clubhouse facilities and a tennis court. Located in Sembawang, The Nautical is just a few minutes’ walk from the Sembawang MRT Station. Sembawang Shopping Centre is bringing convenience to the front door within walking distance.`,
    website:"",
    location: {lat: "1.4434", lng: "103.8245"},
    images: [
      {id: 0, imageSrc: require('@/assets/images/property-dev/nautical/1.jpg')},
      {id: 1, imageSrc: require('@/assets/images/property-dev/nautical/2.jpg')},
      {id: 2, imageSrc: require('@/assets/images/property-dev/nautical/3.jpg')},
      {id: 3, imageSrc: require('@/assets/images/property-dev/nautical/4.jpg')},
    ]
  },
  "Forestville": {
    name: "Forestville",
    codeOfProjectType: "CONDOMINIUM",
    projectType: "公寓",
    developer: "Hao Yuan Investment Pte Ltd ",
    tenure: "99-year leasehold",
    psf: "25,800m²",
    completionYear: "2016",
    totalUnits: "653",
    desc: `Developed by Hao Yuan Investment Pte Ltd and managed by MCC Land (Singapore) Pte Ltd with China Jingye Construction Engineering (S) Pte Ltd as the contractor, this executive condominium project has a total site area of 25,800 square metres and a plot ratio of 3.0. The 99-year leasehold project comprises 14 blocks of 13-storey residential flats with a total of 653 units together with a swimming pool, basement car parks and communal facilities. Forestville provides easy access to other areas of Singapore and also enjoys proximity to Woodlands Ring Primary School and Woodlands Ring Secondary School. Forestville is also within driving distance from Orchard Road, Singapore’s popular shopping area. `,
    website:"https://forestville.cos.sg/",
    location: {lat: "38.4735", lng: "122.8903"},
    images: [
      {id: 0, imageSrc: require('@/assets/images/property-dev/forestville/1.jpg')},
      {id: 1, imageSrc: require('@/assets/images/property-dev/forestville/2.jpg')},
      {id: 2, imageSrc: require('@/assets/images/property-dev/forestville/3.jpg')},
      {id: 3, imageSrc: require('@/assets/images/property-dev/forestville/4.jpg')},
      {id: 4, imageSrc: require('@/assets/images/property-dev/forestville/5.jpg')},
      {id: 5, imageSrc: require('@/assets/images/property-dev/forestville/6.jpg')},
    ]
  },
  "Sea Horizon": {
    name: "Sea Horizon",
    codeOfProjectType: "CONDOMINIUM",
    projectType: "公寓",
    developer: "Hao Yuan Development Pte Ltd",
    tenure: "99-year leasehold",
    psf: "27,660m²",
    completionYear: "2016",
    totalUnits: "495",
    desc: `Developed by Hao Yuan Development Pte Ltd and managed by MCC Land (Singapore) Pte Ltd with China Jingye Construction Engineering (S) Pte Ltd as the contractor, the executive condominium project has a total site area of 27,660 square metres and a plot ratio of 2.3. The 99-year leasehold project comprises four 10-storey, three 11-storey, two 12-storey and three 13-storey residential flats with a total of 495 units together with a swimming pool, car park and communal facilities. Inspired by Nordic and Scandinavian architecture, Sea Horizon is located close to Pasir Ris Beach and Pasir Ris Park, offering an uninterrupted view of the ocean as well as a wide range of recreational options. Sea Horizon also enjoys proximity to various educational institutions including Casuarina Primary School, Pasir Ris Primary School, Loyang Primary School, Hai Sing Catholic School, Meridian Junior College, Temasek Polytechnic, United World College Southeast Asia (UWCSEA) and Singapore University of Technology and Design (SUTD). `,
    website:"https://seahorizon.cos.sg/",
    location: {lat: "1.3773", lng: "103.9592"},
    images: [
      {id: 0, imageSrc: require('@/assets/images/property-dev/sea-horizon/1.jpg')},
      {id: 1, imageSrc: require('@/assets/images/property-dev/sea-horizon/2.jpg')},
      {id: 2, imageSrc: require('@/assets/images/property-dev/sea-horizon/3.jpg')},
      {id: 3, imageSrc: require('@/assets/images/property-dev/sea-horizon/4.jpg')},
    ]
  },
  "Queens Peak": {
    name: "Queens Peak",
    codeOfProjectType: "CONDOMINIUM",
    projectType: "公寓",
    developer: "HY Realty (Dundee) Pte Ltd",
    tenure: "99-year leasehold",
    psf: "10,516m²",
    completionYear: "2020",
    totalUnits: "736",
    desc: "Developed by HY Realty (Dundee) Pte Ltd and managed by MCC Land (Singapore) Pte Ltd with China Jingye Construction Engineering (S) Pte Ltd as the main contractor, this condominium project has a total site area of 10,516 square metres with a plot ratio of 5.0. Featuring 736 units comprising 2 blocks of 44 storey residential units, 7 zones of 77 facilities, a childcare centre and 1 shop unit and 593 carpark slots. Located in Queenstown, Queens Peak is within walking distance from the Queenstown MRT Station and is well-connected to Orchard Road, the Central Business District (CBD) and Queens Park. Educational institutions like Queenstown Primary School and the Queenstown Campus of the Global Indian International School are also located nearby.",
    website:"",
    location: {lat: "1.2949", lng: "103.8068"},
    images: [
      {id: 0, imageSrc: require('@/assets/images/property-dev/queens-peak/1.jpg')},
      {id: 1, imageSrc: require('@/assets/images/property-dev/queens-peak/2.jpg')},
      {id: 2, imageSrc: require('@/assets/images/property-dev/queens-peak/3.jpg')},
      {id: 3, imageSrc: require('@/assets/images/property-dev/queens-peak/4.jpg')},
    ]
  },
  "NorthWave": {
    name: "NorthWave",
    codeOfProjectType: "CONDOMINIUM",
    projectType: "公寓",
    developer: "Hao Yuan (Woodlands) Pte Ltd",
    tenure: "99-year leasehold",
    psf: "12,388m²",
    completionYear: "2019",
    totalUnits: "358",
    desc: `Developed by Hao Yuan (Woodlands) Pte Ltd and managed by MCC Land (Singapore) Pte Ltd with China Jingye Construction Engineering (S) Pte Ltd as the main contractor, this executive condominium project has a total site area of 12,388 square metres and a plot ratio of 2.8. The 99-year leasehold project comprises six 16-storey residential flats with a total of 358 units together with basement car parks, swimming pools and communal facilities. Located close to the Admiralty MRT Station and the Thomson-East Coast MRT Line as well as the future North-South Corridor (originally conceptualized as the North-South Expressway), Northwave is a well-connected residence at the fringe of the Woodlands Regional Centre. Northwave also enjoys proximity to major highways such as the Seletar Expressway (SLE) and Central Expressway (CTE), bringing convenience closer to the door. `,
    website:"",
    location: {lat: "1.4435", lng: "103.8104"},
    images: [
      {id: 0, imageSrc: require('@/assets/images/property-dev/north-wave/1.jpg')},
      {id: 1, imageSrc: require('@/assets/images/property-dev/north-wave/2.jpg')},
      {id: 2, imageSrc: require('@/assets/images/property-dev/north-wave/3.jpg')},
      {id: 3, imageSrc: require('@/assets/images/property-dev/north-wave/4.jpg')},
      {id: 4, imageSrc: require('@/assets/images/property-dev/north-wave/5.jpg')},
    ]
  },
}