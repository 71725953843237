export default {
  "Sceneca": {
    name: "景乐苑及景乐坊",
    codeOfProjectType: "CONDOMINIUM",
    projectType: "豪华综合发展项目",
    developer: "MCC LAND (TMK) PTE LTD",
    tenure: "99年",
    psf: "8,880 m²",
    completionYear: "2026",
    totalUnits: "268",
    desc: `
    景乐苑及景乐坊综合发展项目总占地面积8,880平方米， 这个拥有99年地契的发展项目由两座14/15层的高楼组成，合计共有268个公寓单位，单位类型包括一卧室、一卧室+书房、两卧室、两卧室+书房、三卧室、四卧室，以及四间顶层豪华四卧室公寓。该项目休闲设施包括俱乐部会所、健身房、50米的游泳池、水上步道、家庭休息室、休憩亭、儿童游乐场、儿童嬉水池、秋千花园、多功能大厅、烧烤亭等。
    
    该项目位于新加坡东部的丹那美拉克基连路，衔接丹那美拉地铁站，毗邻巴士转换站、轻轨列车站等主要交通枢纽；对于驾车人士来说，只需几分钟车程，便可以从景乐苑开往泛岛快速公路（PIE）、淡滨尼快速公路（TPE）和加冷—巴耶利峇快速公路（KPE）等高速公路。地面层建有景乐苑商场，包含一家一千平方米的智能超市及两个社区活动广场；这里还拥有各种便利设施，如超市、零售店、娱乐中心、咖啡座、餐厅、银行。能一站式满足衣、食、住、行、游、购、娱的多元化生活方式，居民坐享工作与生活的便利，投资自住皆宜。
    `,
    website:"https://www.scenecaresidence.com.sg/",
    location: {lat: 1.327959328803544, lng: 103.94456735474941},
    images: [
      {id: 0, imageSrc: require("@/assets/images/property-dev/sceneca/sceneca_1.png")},
      {id: 1, imageSrc: require("@/assets/images/property-dev/sceneca/sceneca_2.png")},
      {id: 2, imageSrc: require("@/assets/images/property-dev/sceneca/sceneca_3.png")},
      {id: 3, imageSrc: require("@/assets/images/property-dev/sceneca/sceneca_4.png")},
      {id: 4, imageSrc: require("@/assets/images/property-dev/sceneca/sceneca_5.png")},
      {id: 5, imageSrc: require("@/assets/images/property-dev/sceneca/sceneca_6.png")},
      {id: 6, imageSrc: require("@/assets/images/property-dev/sceneca/sceneca_7.png")},
      {id: 7, imageSrc: require("@/assets/images/property-dev/sceneca/sceneca_8.png")},
      {id: 8, imageSrc: require("@/assets/images/property-dev/sceneca/sceneca_9.png")},
      {id: 9, imageSrc: require("@/assets/images/property-dev/sceneca/sceneca_10.png")},
    ]
  },
  "One Bernam": {
    name: "One Bernam",
    codeOfProjectType: "CONDOMINIUM",
    projectType: "Condominium mixed-use development",
    developer: "HY-MCC (Bernam) Pte Ltd",
    tenure: "99-year leasehold",
    psf: "3,846m²",
    completionYear: "2025",
    totalUnits: "350",
    desc: "Developed by HY-MCC (Bernam) Pte Ltd with China Jingye Construction Engineering (S) Pte Ltd as the main contractor, this mixed-use development has a project size of 3,846 square metres and a gross plot ratio of 7.28. The 99-year leasehold project is a 35-storey development comprising two-storey commercial, one-storey service apartments, 32-storey residential flats with a total of 351 units, with swimming pool, communal facilities, and a multi-storey car park. Located at the heart of the Central Business District (CBD), One Bernam is served by the Tanjong Pagar MRT Station, and the upcoming Prince Edward Road and Maxwell MRT Station, as well as the Ayer Rajah Expressway (AYE), the Marina Coastal Expressway (MCE), the East Coast Expressway (ECP). Within reach are Cantonment Primary School and the National Library, while VivoCity is just a short drive away. One Bernam also enjoys proximity to Marina Bay Sands and Great World City.",
    website:"",
    location: {lat: 1.2738161947588205, lng: 103.84360571710371},
    images: [
      {id: 0, imageSrc: require("@/assets/images/property-dev/one-bernam/ClubhouseNite-6K-Fn4.gif")},
      {id: 1, imageSrc: require("@/assets/images/property-dev/one-bernam/L34_FunctionRoom-6K-3.gif")},
      {id: 2, imageSrc: require("@/assets/images/property-dev/one-bernam/2.gif")},
      {id: 3, imageSrc: require("@/assets/images/property-dev/one-bernam/Aerial02-6K-ExtFn1.gif")},
      {id: 4, imageSrc: require("@/assets/images/property-dev/one-bernam/ClubhouseINTDay-6K-Fn3.gif")},
      {id: 5, imageSrc: require("@/assets/images/property-dev/one-bernam/Hammock-8K-Fn2.gif")},
      {id: 6, imageSrc: require("@/assets/images/property-dev/one-bernam/Hero02-6K-Fn2.gif")},
      {id: 7, imageSrc: require("@/assets/images/property-dev/one-bernam/Pool1-8k-Fn5.gif")},
    ]
  },
  "Provence Residence": {
    name: "Provence Residence",
    codeOfProjectType: "CONDOMINIUM",
    projectType: "Executive Condominium",
    developer: "MCC Land (Canberra) Pte Ltd",
    tenure: "99-year Leasehold",
    psf: "16,690m²",
    completionYear: "2025",
    totalUnits: "413",
    website:"https://provenceresidence.com.sg",
    location: {lat: 1.4481037539401864, lng: 103.82743607245057},
    desc: `Provence Residence executive condominium is located at Canberra Crescent. The development comprises of a total of 413 units comprising of 3- and 4-bedroom layouts.

    Due to its excellent location, Provence Residence is only minutes' walk to Canberra MRT station. Provence Residence is also located within proximity to Sports and Integrated Hub (Bukit Canberra) and Canberra Plaza hence it will appeal to residents who like convenience at their doorstep.`,
    images: [
      {id: 0, imageSrc: require('@/assets/images/property-dev/provence-residence/PR6.gif')},
      {id: 1, imageSrc: require('@/assets/images/property-dev/provence-residence/PR1.gif')},
      {id: 1, imageSrc: require('@/assets/images/property-dev/provence-residence/PR2.gif')},
      {id: 1, imageSrc: require('@/assets/images/property-dev/provence-residence/PR3.gif')},
      {id: 1, imageSrc: require('@/assets/images/property-dev/provence-residence/PR4.gif')},
      {id: 1, imageSrc: require('@/assets/images/property-dev/provence-residence/PR5.gif')},
    ]
  },
  "Landmark": {
    name: "Landmark",
    codeOfProjectType: "CONDOMINIUM",
    projectType: "公寓",
    developer: "Landmark JV Pte Ltd",
    tenure: "99-year leasehold",
    psf: "5,650m²",
    completionYear: "2025",
    totalUnits: "396",
    desc: "Developed by Landmark JV Pte Ltd (ZACD Group Ltd, MCC Land (Singapore) Pte Ltd and SSLE Development Pte Ltd), this luxury condominium project has a gross plot ratio of 4.25. The 99-year leasehold project comprises one 39-storey residential flat with 396 units in total together with two sky terraces, a landscape deck and three basement car parks. Located along Chin Swee Road, The Landmark enjoys proximity to the Outram Park MRT Station and Chinatown MRT Station. Within walking distance is the Robertson Quay, and River Valley Primary School is also a short drive away. The Landmark is near two major growth areas: the upcoming Singapore General Hospital (SGH) Campus, which will be Singapore’s largest medical campus when completed, and the Greater Southern Waterfront, lifestyle amenities and proximity to parks, about six times the size of Marina Bay.",
    website:"https://www.thelandmarksingapore.com/",
    location: {lat: 1.2862705857143404, lng: 103.83957393746043},
    images: [
      {id: 0, imageSrc: require('@/assets/images/property-dev/landmark/7.gif')},
      {id: 1, imageSrc: require('@/assets/images/property-dev/landmark/6.gif')},
      // {id: 2, imageSrc: require('@/assets/images/property-dev/landmark/11.gif')},
      {id: 3, imageSrc: require('@/assets/images/property-dev/landmark/1.gif')},
      {id: 4, imageSrc: require('@/assets/images/property-dev/landmark/2.gif')},
      {id: 5, imageSrc: require('@/assets/images/property-dev/landmark/4.gif')},
      {id: 6, imageSrc: require('@/assets/images/property-dev/landmark/5.gif')},
      {id: 7, imageSrc: require('@/assets/images/property-dev/landmark/8.gif')},
      // {id: 8, imageSrc: require('@/assets/images/property-dev/landmark/9.gif')},
      {id: 9, imageSrc: require('@/assets/images/property-dev/landmark/10.gif')},
    ]
  },
  "Sky Villa": {
    name: "Sky Villa",
    codeOfProjectType: "CONDOMINIUM",
    projectType: "Luxury residential apartments",
    developer: "Greatview Investment",
    tenure: "",
    psf: "120,000m²",
    completionYear: "2020",
    totalUnits: "256",
    desc: `Designed by internationally renowned Taiwanese architect Chu-Yuan Lee (C.Y. Lee), developed by Greatview Investment & Crystal Orange, project managed by MCC Land (Singapore) Pte Ltd and property managed by AJ Hotel Group & AJ Property Management, the project has a total construction area of 120,000 square metres. Sky Villa comprises two 35-storey towers of luxury residential apartments in the prime district of central Phnom Penh. The property features 256 units ideal for personal or multi-generational family living with generous residential spaces that range from 272 square metres to 509 square metres. Amenities and facilities include swimming pools, in-house restaurant and coffee shops, entertainment rooms, gym, sauna, sports clubs, and a sky garden. Shopping malls, international schools, government embassies and financial institutions are a stone’s throw away from home. The property is also supported by an excellent transport network and is readily accessible via major roads and highways. `,
    website:"http://skyvilla.com.kh/",
    location: {lat: 11.556421799729065, lng: 104.9142663656631},
    images: [
      {id: 0, imageSrc: require('@/assets/images/property-dev/sky-vila/1.jpg')},
      {id: 1, imageSrc: require('@/assets/images/property-dev/sky-vila/2.jpg')},
      {id: 2, imageSrc: require('@/assets/images/property-dev/sky-vila/3.jpg')},
      {id: 3, imageSrc: require('@/assets/images/property-dev/sky-vila/4.jpg')},
    ]
  },
  "JKT Living Star": {
    name: "JKT Living Star",
    codeOfProjectType: "CONDOMINIUM",
    projectType: "",
    developer: "MCC Land (Singapore) Pte Ltd",
    tenure: "",
    psf: "200,000m²",
    completionYear: "2021",
    totalUnits: "3,648",
    desc: `Covering an area of 4.8 hectares, floor area ratio of 3.4, with total project landscaping ratio of 65%, the project is a large-scale mid-to-high-end urban complex developed by MCC Land (Singapore) Pte Ltd and SDL Real Estate, comprising six condominiums as well as commercial districts. With a total of 3,648 units and 1100 parking spaces, the project is located in JL Raya Bogor (Pasar Rebo), the core location of the Southeast Jakarta area with urban facilities and convenient living. JKT Living Star also enjoys proximity to the upcoming Jakarta Light Rail Transit (LRT) as well as the Ciracas and Cibubur Station. `,
    website:"http://www.jkt-livingstar.com/?lang=en-us",
    location: {lat: -6.344026839731602, lng: 106.87062561564053},
    images: [
      {id: 0, imageSrc: require('@/assets/images/property-dev/living-star/6.gif')},
      {id: 1, imageSrc: require('@/assets/images/property-dev/living-star/1.gif')},
      {id: 2, imageSrc: require('@/assets/images/property-dev/living-star/2.gif')},
      {id: 3, imageSrc: require('@/assets/images/property-dev/living-star/4.gif')},
      {id: 4, imageSrc: require('@/assets/images/property-dev/living-star/5.gif')},
      {id: 5, imageSrc: require('@/assets/images/property-dev/living-star/8.gif')},
      {id: 6, imageSrc: require('@/assets/images/property-dev/living-star/10.gif')},
     /*  {id: 7, imageSrc: require('@/assets/images/property-dev/living-star/3.jpg')},
      {id: 8, imageSrc: require('@/assets/images/property-dev/living-star/7.jpg')},
      {id: 9, imageSrc: require('@/assets/images/property-dev/living-star/9.jpg')}, */
    ]
  },
  "The Santorini": {
    name: "The Santorini",
    codeOfProjectType: "CONDOMINIUM",
    projectType: "Condominium",
    developer: "MCC Land (Singapore) Pte Ltd",
    tenure: "",
    psf: "17,102m²",
    completionYear: "2017",
    totalUnits: "597",
    desc: "Inspired by the clear blue seas and romance of the Greek islands. The Santorini brings paradise to life. Together with its prime position surrounded by a raft of amenities, it is both an ideal home and an intelligent investment. Developed by MCC Land (Singapore) Pte Ltd. Residents of the 597 units at The Santorini can enjoy a world of luxurious facilities.The Aegean Arrival welcomes you with fanfare, where a water wall cascades rhythmically onto characteristic Greek boulders.",
    website:"https://thesantorini.condoinsingapore.com/",
    location: {lat: 1.3575659168275267, lng: 103.92747575348241},
    images: [
      {id: 0, imageSrc: require('@/assets/images/property-dev/the-santorini/1.jpg')},
      {id: 1, imageSrc: require('@/assets/images/property-dev/the-santorini/2.jpg')},
      {id: 2, imageSrc: require('@/assets/images/property-dev/the-santorini/3.jpg')},
      {id: 3, imageSrc: require('@/assets/images/property-dev/the-santorini/4.jpg')},
      {id: 4, imageSrc: require('@/assets/images/property-dev/the-santorini/5.jpg')},
    ]
  },
}