<template>
  <div
    class="
      row-pagination
      bg-c-2
      flex-row
      justify-content-center
      align-items-center
    "
  >
    <div class="box">
      <div class="flex-row align-items-center">
        <p class="flex-1 f-c-2 f-f-arial f-s-11 flex-row align-items-center">
          <i class="iconfont iconarrow-left"></i
          ><span class="cursor-pointer" @click="onPagination(preProjectName)"
            >Previous</span
          >
        </p>
        <p
          class="
            f-c-2 f-f-arial f-s-11
            cursor-pointer
            flex-row
            align-items-center
          "
          @click="onPagination(nextProjectName)"
        >
          Next<i class="iconfont iconarrow-right"></i>
        </p>
      </div>

      <gap :height="16" />

      <div class="flex-row">
        <p class="flex-1 f-c-2 f-s-17 f-f-arial font-bold">
          {{ preProjectName }}
        </p>
        <p class="f-c-2 f-s-17 f-f-arial font-bold">
          {{ nextProjectName }}
        </p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    currentProjectId: String,
    projectList: Object,
  },
  data() {
    return {
      preProjectName: '',
      nextProjectName: ''
    }
  },
  created() {
    this.findPreAndNextProject(this.currentProjectId);
  },
  methods: {
    findPreAndNextProject(currentProjectName) {
      const arrProjectName = Object.keys(this.projectList);
      const index = arrProjectName.indexOf(currentProjectName);

      if (index <= 0) {
        this.preProjectName = "";
        this.nextProjectName = arrProjectName[index + 1];
      } else if (index >= arrProjectName.length - 1) {
        this.nextProjectName = "";
        this.preProjectName = arrProjectName[index - 1];
      } else {
        this.preProjectName = arrProjectName[index - 1];
        this.nextProjectName = arrProjectName[index + 1];
      }
    },
    onPagination(projectName) {
      this.findPreAndNextProject(projectName);
      this.$emit('on-pagination', projectName);
    }
  }
};
</script>
<style lang="scss" scoped>
.row-pagination {
  @include box-size(100%, 25.7rem);

  .box {
    width: 65.9rem;

    .iconarrow-left {
      padding-right: 11px;
    }
    .iconarrow-right {
      padding-left: 11px;
    }
  }
}
</style>