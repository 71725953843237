export default {
  "The ALPS": {
    name: "The ALPS",
    codeOfProjectType: "CONDOMINIUM",
    projectType: "Condominium",
    developer: "MCC Land (Tampines) Pte Ltd",
    tenure: "99-year leasehold",
    psf: "16,660m²",
    completionYear: "2019",
    totalUnits: "626 units together with two commercial lots",
    desc: "Inspired by the Swiss Alps, the iconic skyline of The Alps Residences cascades downward with lush green terraces. Located along Tampines Street 86, developed by MCC Land (Tampines) Pte Ltd. The Alps Residences comprises 626 units of 1 to 4 bedroom types as well as single level penthouses. It is well connected via KPE/PIE to downtown, or even SLE to Seletar Aerospace Park as the journeys only require 15 minutes. Situated near Our Tampines Hub, OTH is Singapore’s largest integrated community and lifestyle hub, bringing together multiple agencies and offering a comprehensive range of services and facilities.",
    website:"http://thealpsresidences.com.sg/",
    location: {lat: 1.3547426, lng: 103.9275375},
    images: [
      {id: 0, imageSrc: require('@/assets/images/property-dev/alps/1.jpg')},
      {id: 1, imageSrc: require('@/assets/images/property-dev/alps/2.jpg')},
      {id: 2, imageSrc: require('@/assets/images/property-dev/alps/3.jpg')},
      {id: 3, imageSrc: require('@/assets/images/property-dev/alps/4.jpg')},
    ]
  },
  "Poiz": {
    name: "The Poiz Residences & Centre",
    codeOfProjectType: "Mixed-use development",
    projectType: "mixed-development condominium",
    developer: "MCC Land (Singapore) Pte Ltd",
    tenure: "99-year leasehold",
    psf: "16,149m²",
    completionYear: "2018",
    totalUnits: "731",
    desc: "Developed by MCC Land (Potong Pasir) Pte Ltd with China Jingye Engineering Corporation Limited (Singapore Branch) as the main contractor, this mixed-use development has a total site area of 16,149 square metres with a plot ratio of 3.5. The 99-year leasehold project comprises eight 18-storey residential flats with a total of 731 units together with a swimming pool and communal facilities. Located at Meyappa Chettiar Road in District 13, The Poiz Residences is within walking distance from the Potong Pasir MRT Stations, providing easy access to other areas of Singapore. The Poiz Residences also enjoy proximity to St. Andrew’s Secondary School and St. Andrew’s Junior School. It is conveniently located within minutes from Potong Pasir Community Club, post office and the banks. It is also highly accessible via expressways to Orchard Road and the Central CBD.",
    website:"https://thepoizcentre.com.sg/",
    location: {lat: "1.3314", lng: "103.8684"},
    images: [
      {id: 0, imageSrc: require("@/assets/images/property-dev/poiz/1.jpg")},
      {id: 1, imageSrc: require("@/assets/images/property-dev/poiz/2.jpg")},
      {id: 2, imageSrc: require("@/assets/images/property-dev/poiz/3.jpg")},
      {id: 3, imageSrc: require("@/assets/images/property-dev/poiz/4.jpg")},
      {id: 4, imageSrc: require("@/assets/images/property-dev/poiz/5.jpg")},
    ]
  },
  "One Canberra": {
    name: "One Canberra",
    codeOfProjectType: "CONDOMINIUM",
    projectType: "Executive condominium",
    developer: "MCC Land (Singapore) Pte Ltd",
    tenure: "99-year leasehold",
    psf: "27,154m²",
    completionYear: "2015",
    totalUnits: "696",
    desc: "One Canberra enjoys a riverfront location along Simpang Kiri Park Connector. Bustling Yishun Central and idyllic Sembawang are also very close by. From MRT stations, malls, supermarkets, cinemas to food centres and recreational facilities, all you need is within easy reach. Inspired by the riverfront location, One Canberra boasts four amazing worlds of facilities, each with its own distinct allure.",
    website:"",
    location: {lat: "1.4378", lng: "103.8297"},
    images: [
      {id: 0, imageSrc: require('@/assets/images/property-dev/one-canberra/1.jpg')},
      {id: 1, imageSrc: require('@/assets/images/property-dev/one-canberra/2.jpg')},
      {id: 2, imageSrc: require('@/assets/images/property-dev/one-canberra/3.jpg')},
      {id: 3, imageSrc: require('@/assets/images/property-dev/one-canberra/4.jpg')},
    ]
  },
  "Canberra Residences": {
    name: "Canberra Residences",
    codeOfProjectType: "CONDOMINIUM",
    projectType: "Condominium",
    developer: "MCC Land (Singapore) Pte Ltd",
    tenure: "99-year leasehold",
    psf: "22,567m²",
    completionYear: "2013",
    totalUnits: "320",
    desc: "Welcome to a resort-inspired enclave that seamlessly blend its inhabitants, both nature and humankind, in tune with the ageless chords of peace and harmony. Spanning the entire sanctuary, the landscape is likened to a paradisiacal valley of rocks, trees and water. The curious shall traverse deeper, into an endless vista of floral splendor, as the richness of the valley graces those who enter.",
    website:"",
    location: {lat: "1.4450", lng: "103.8251"},
    images: [
      {id: 0, imageSrc: require('@/assets/images/property-dev/canberra-residences/1.jpg')},
      {id: 1, imageSrc: require('@/assets/images/property-dev/canberra-residences/2.jpg')},
      {id: 2, imageSrc: require('@/assets/images/property-dev/canberra-residences/3.jpg')},
      {id: 3, imageSrc: require('@/assets/images/property-dev/canberra-residences/4.jpg')},
      {id: 4, imageSrc: require('@/assets/images/property-dev/canberra-residences/5.jpg')},
    ]
  },
  "The Canopy": {
    name: "The Canopy",
    codeOfProjectType: "CONDOMINIUM",
    projectType: "Executive condominium",
    developer: "MCC Land (Singapore) Pte Ltd",
    tenure: "99-year leasehold",
    psf: "42,207m²",
    completionYear: "2014",
    totalUnits: "406",
    desc: `Nestled close to the Lower Seletar Reservoir and Yishun Park on Yishun Avenue 11, The Canopy is the perfect resort home for those who draw inspiration from the beauty of nature.
    With all eight towers fronted by inviting pools and lush landscaping inspired by nature, life at The Canopy will always be refreshing and rejuvenating.`,
    website:"",
    location: {lat: "51.4479", lng: "2.5967"},
    images: [
      {id: 0, imageSrc: require('@/assets/images/property-dev/one-canopy/1.jpg')},
      {id: 1, imageSrc: require('@/assets/images/property-dev/one-canopy/2.jpg')},
      {id: 2, imageSrc: require('@/assets/images/property-dev/one-canopy/3.jpg')},
      {id: 3, imageSrc: require('@/assets/images/property-dev/one-canopy/4.jpg')},
    ]
  },
}