export default {
  "Maritime Experiential Museum": {
    name: "新加坡海事博物馆",
    projectType: "特色主题公园",
    developer: "",
    tenure: "",
    psf: "",
    completionYear: "2010",
    totalUnits: "",
    desc: `该项目位于新加坡圣淘沙岛，属于临海复杂岩土地质条件下建造的深基础、大跨度钢筋混凝土结构、钢结构的大型公共建筑，综合应用和开发了岩土、混凝土结构、钢结构多项新技术。`,
    website:"",
    location: {lat: 1.2569975772871185, lng: 103.82026726188299},
    images: [
      {id: 0, imageSrc: require('@/assets/images/constructions/project-theme-park/museum/1.gif')},
      {id: 1, imageSrc: require('@/assets/images/constructions/project-theme-park/museum/DJI_0026.gif')}
    ]
  },
  "Universal Studios Singapore": {
    name: "新加坡环球影城",
    projectType: "特色主题公园",
    developer: "",
    tenure: "",
    psf: "",
    completionYear: "2009",
    totalUnits: "",
    desc: `新加坡环球影城（Universal Studios Singapore），是继美国拉斯维加斯、奥兰多、
    日本大阪之后的第四座环球影城，是一座集娱乐、休闲、观光、餐饮为一体的综合娱乐主题公园。
    新加坡环球影城项目总承包建安合同额超过8亿新币（约人民币40亿），中冶新加坡用不足18个月的时间完成了美国、日本需4至5年才能完成的工程，创造了世界A级主题公园建设史上的奇迹。
    新加坡环球影城项目广受赞誉，先后获得新加坡绿色建筑Green Mark GOLD
    PLUS大奖、中国建筑鲁班奖等，并取得了18项专利申请`,
    website:"https://www.rwsentosa.com/en/attractions/universal-studios-singapore/explore",
    location: {lat: 1.254056734461937, lng: 103.82381008255646},
    images: [
      {id: 0, imageSrc: require('@/assets/images/constructions/project-theme-park/studio/1.jpg')},
      {id: 1, imageSrc: require('@/assets/images/constructions/project-theme-park/studio/2.jpg')},
      {id: 2, imageSrc: require('@/assets/images/constructions/project-theme-park/studio/3.jpg')},
      {id: 3, imageSrc: require('@/assets/images/constructions/project-theme-park/studio/20210708104745.gif')},
      {id: 4, imageSrc: require('@/assets/images/constructions/project-theme-park/studio/USS.gif')}
    ]
  },
}