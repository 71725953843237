import onSale from "./pd-on-sale";
import themePark from "./pd-theme-park";
import hdb from "./pd-hdb";
import publicProject from "./pd-public";

export default {
  ...onSale,
  ...themePark,
  ...hdb,
  ...publicProject,
}