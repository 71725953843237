export default {
  "DANGA HEIGHTS": {
    name: "DANGA HEIGHTS",
    /* projectType: "Urban Planning and Design",
    developer: "",
    tenure: "",
    psf: "600,000m²",
    completionYear: "",
    totalUnits: "", */
    arrForDetail: [
      {title: 'Project Name', label: 'DANGA HEIGHTS'},
      {title: 'Project Type', label: 'Township Development'},
      {title: 'Developer', label: 'MCC DANGA BAY SDN BHD'},
      {title: 'Country', label: 'Malaysia'},
      {title: 'Site Area', label: '60 ha'},
      {title: 'Completion Year', label: 'On going'},
      {
        title: 'Services', 
        label: `Urban Planning and Design<br/>
        Design Management for Archi, C&S and M&E <br/>
        BIM Modelling`
      },
    ],
    desc: `
    With an overall project area of 60ha, Danga Heights is a joint venture between MCC Singapore and Danga Heights Development (DHD), a subsidiary of Iskandar Waterfront Holdings (IWH), and is to be developed over 10 years in four phases with a gross development value of RM8 billion. The first phase development is at the 15.4ha prime land. 

    The project is located at the Iskandar Development Region of Johor Bahru, the main southern development corridor of the second largest city in Malaysia. Close to the Johor Bahru CBD and adjacent to Skudai Highway. 
    
    The project is envisioned to become the new uptown area of Johor — a smart city with intelligent features and cutting-edge home concepts that will catalyse Johor’s urban transformation into an even more technologically advanced city in the next decade. 
    
    Danga Heights is one of the biggest foreign direct investments (FDIs) in Iskandar, Malaysia, following the COVID-19 outbreak and also marks MCC Singapore’s first foray into the real estate industry of Malaysia.
    
    `,
    website:"",
    location: {lat: "", lng: ""},
    images: [
      {id: 0, imageSrc: require('@/assets/images/urban-design/danga/F_01.gif')},
      {id: 1, imageSrc: require('@/assets/images/urban-design/danga/F_02.gif')},
      {id: 2, imageSrc: require('@/assets/images/urban-design/danga/F_03.gif')},
      {id: 3, imageSrc: require('@/assets/images/urban-design/danga/F_04.gif')},
      {id: 4, imageSrc: require('@/assets/images/urban-design/danga/F_05.gif')},
    ]
  },
  "DARA SAKOR": {
    name: "DARA SAKOR",
    /* projectType: "City Development",
    developer: "Coastal City Development Group Co. Ltd.",
    tenure: "",
    psf: "360km²",
    completionYear: "2019",
    totalUnits: "", */
    arrForDetail: [
      {title: 'Project Name', label: 'DARA SAKOR'},
      {title: 'Project Type', label: 'City Development'},
      {title: 'Developer', label: 'Coastal City Development Group Co. Ltd.'},
      {title: 'Country', label: 'Cambodia'},
      {title: 'Site Area', label: '360km²'},
      {title: 'Completion Year', label: '2019'},
      {title: 'Services', label: 'Master Planning and Urban Design'},
    ],
    desc: `Dara Sakor is a 360 square kilometres L-shape development located along the southwest coast of Koh Kong Province, Cambodia, which lies along the Gulf of Thailand. The project is situated approximately 260 kilometres to Phnom Penh, 560 kilometres to Angkor Wat, and 60 kilometres to Sihanoukville. The project draws on Singapore experience, includs urban planning & management, environmental protection, resource conservation, water management, waste disposal and sustainable development, introducing advanced construction and development models during the planning of this project.
    
    The overall project has 3 functional zones. The 100 km² International District will become Cambodia's new integrated tourist destination; The 60 km² World Trade District is positioned as the emerging business and financial centre in Southeast Asia; The 200 km² Future District will be the intelligent technology new town. Creating a distinctive coastal city image, introducing high-value industry such as tourism, business, R&D, etc. and attracting talents by the vibrant liveable living environment, are the planning directions of this project.
    `,
    website:"",
    location: {lat: "", lng: ""},
    images: [
      // {id: 0, imageSrc: require("@/assets/images/urban-design/dara-sakor/American_7.jpg")},
      // {id: 1, imageSrc: require("@/assets/images/urban-design/dara-sakor/c01-Overall_aerial-010.jpg")},
      {id: 2, imageSrc: require("@/assets/images/urban-design/dara-sakor/c02-3.gif")},
      {id: 3, imageSrc: require("@/assets/images/urban-design/dara-sakor/c02-aerial.gif")},
      // {id: 4, imageSrc: require("@/assets/images/urban-design/dara-sakor/c04_04.JPG")},
      {id: 5, imageSrc: require("@/assets/images/urban-design/dara-sakor/c5.gif")},
      {id: 6, imageSrc: require("@/assets/images/urban-design/dara-sakor/C08.gif")},
      // {id: 7, imageSrc: require("@/assets/images/urban-design/dara-sakor/Cambodian_9.gif")},
      // {id: 8, imageSrc: require("@/assets/images/urban-design/dara-sakor/Japanese_7.gif")},
      {id: 9, imageSrc: require("@/assets/images/urban-design/dara-sakor/Site_plan_9.gif")},
    ]
  },
}