export default {
  "Sunshine Gardens": {
    name: "Sunshine Gardens",
    projectType: "HDB",
    developer: "",
    tenure: "",
    psf: "",
    completionYear: "2015",
    totalUnits: "1528",
    desc: "With China Jingye Engineering Corporation Limited (Singapore Branch) as the main contractor, this Housing and Development Board (HDB) project comprises one 12-storey, five 13-storey, six 14-storey and three 15-storey residential flats with a total of 1,528 units together with two multi-storey car parks and rooftop gardens. The project commenced work in 2012 and was completed in 2015.",
    website:"",
    location: {lat: 1.3762039468854808, lng: 103.73962537353975},
    images: [
      {id: 0, imageSrc: require('@/assets/images/constructions/project-hdb/sunshine-gardens/1.jpg')},
      {id: 1, imageSrc: require('@/assets/images/constructions/project-hdb/sunshine-gardens/2.jpg')},
      {id: 2, imageSrc: require('@/assets/images/constructions/project-hdb/sunshine-gardens/3.jpg')},
      {id: 3, imageSrc: require('@/assets/images/constructions/project-hdb/sunshine-gardens/4.jpg')},
    ]
  },
  "Punggol Emerald": {
    name: "Punggol Emerald",
    projectType: "HDB",
    developer: "",
    tenure: "",
    psf: "",
    completionYear: "2015",
    totalUnits: "856",
    desc: "With China Jingye Engineering Corporation Limited (Singapore Branch) as the main contractor, this Housing and Development Board (HDB) project comprises one 12-storey, five 13-storey, six 14-storey and three 15-storey residential flats with a total of 1,528 units together with two multi-storey car parks and rooftop gardens. The project commenced work in 2012 and was completed in 2015.",
    website:"",
    location: {lat: 1.404821887387103, lng: 103.89842711464412},
    images: [
      {id: 0, imageSrc: require('@/assets/images/constructions/project-hdb/punggol-emerald/1.gif')},
      {id: 1, imageSrc: require('@/assets/images/constructions/project-hdb/punggol-emerald/2.gif')},
      {id: 2, imageSrc: require('@/assets/images/constructions/project-hdb/punggol-emerald/3.gif')},
      {id: 3, imageSrc: require('@/assets/images/constructions/project-hdb/punggol-emerald/4.gif')},
    ]
  },
  "Anchorvale Horizon": {
    name: "Anchorvale Horizon",
    projectType: "HDB",
    developer: "",
    tenure: "",
    psf: "",
    completionYear: "2014",
    totalUnits: "745",
    desc: "With China Jingye Engineering Corporation Limited (Singapore Branch) as the main contractor, this Housing and Development Board (HDB) project comprises seven 16-storey residential flats with a total of 745 units. The project commenced work in 2011 and was completed in 2014.",
    website:"",
    location: {lat: 1.3948390791294287, lng: 103.88806947600023},
    images: [
      {id: 0, imageSrc: require('@/assets/images/constructions/project-hdb/anchorvale-horizon/1.gif')},
      {id: 1, imageSrc: require('@/assets/images/constructions/project-hdb/anchorvale-horizon/2.gif')},
      {id: 2, imageSrc: require('@/assets/images/constructions/project-hdb/anchorvale-horizon/3.gif')},
      {id: 3, imageSrc: require('@/assets/images/constructions/project-hdb/anchorvale-horizon/4.gif')},
    ]
  },
}